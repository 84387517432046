import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import HeaderImage from "../../components/header/headerImage/headerImage";
import BoxContent from "../../components/boxContent/boxContent";
import TextImageContent from "../../components/textImageContent/textImageContent";

import Button from "../../components/button/button";
import "./style.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import headerImg from "../../images/nos-engagements/header.webp";

import gillesCoppereImg from "../../images/nos-engagements/gilles_coppéré.webp";
import interieurImg from "../../images/nos-engagements/interieur.webp";
import rechargeVoitureImg from "../../images/nos-engagements/recharge_voiture.webp";
import enginImg from "../../images/nos-engagements/engin.webp";
import discussionImg from "../../images/nos-engagements/discussion.webp";
import poubelleImg from "../../images/nos-engagements/poubelle.webp";
import batimentImg from "../../images/nos-engagements/batiment.webp";

const NosEngagementsEnvironnementaux = ({location}) => {
    const leviers = [
        "Maîtriser notre **impact** au quotidien."
        ,"Accompagner la **transition** de nos sociétaires."
        ,"**Agir** aujourd’hui pour préserver demain."
    ];
    const impacts = [
        {"texte":"Nous sommes engagés dans un vaste plan de **dématérialisation** de nos activités et visons une politique zéro papier pour les années à venir. <br><br> Outre l’impact directement lié à l’économie de papier, ce projet d’envergure permettra à nos équipes de **limiter leur empreinte carbone**   au quotidien en adaptant leurs pratiques et en misant sur des outils digitaux."
        ,"imageFirst":false, "image":gillesCoppereImg,
        },
        {"texte":"Au printemps 2022, nos équipes ont pris possession de leur nouveau siège social lyonnais. <br><br> Nous avons pris le **parti engagé et ambitieux de la réhabilitation lourde** et non de la construction neuve, avec pour objectif un bâtiment vertueux, pensé pour accompagner les modes de déplacement doux de nos collaborateurs et prétendant aux dernières normes en matière de **performance et consommation énergétique**."
        ,"image":interieurImg,"imageFirst":true,},
        {"texte":"L’Auxiliaire est un acteur de l’**assurance de terrain** et nous avons à coeur d’entretenir des relations de forte proximité avec nos entrepreneurs assurés, notamment en nous déplaçant très régulièrement sur leurs chantiers ou dans leurs entreprises. <br><br> Afin de **réduire nos émissions** au quotidien, nous avons également fait le choix de remplacer notre flotte de véhicules commerciaux par des véhicules hybrides rechargeables."
        ,"image":rechargeVoitureImg, "imageFirst":false,}
    ];
    const transitionSocietaires = [
        {
            "texte": "Afin de permettre aux entreprises du BTP d’accélérer leur transition environnementale, nous avons bâti la première **assurance professionnelle à impact responsable** spécifique aux engins et matériels de chantier **zéro émission**&nbsp;! <br><br>Notre offre **valorise l’engagement environnemental** de nos sociétaires par une tarification avantageuse et des services innovants pour les accompagner dans leurs trajectoires bas carbone.",
            "imageFirst": false,
            "image": enginImg,
            "button": true,
            "buttonUrl": "/breez"
        },
        {
            "texte": "En partenariat avec BTP Rhône et Métropole et WeCount, acteur spécialiste en accompagnement à la transition bas-carbone, nous soutenons les entreprises de la filière qui souhaitent s’engager pleinement et concrètement dans la transition environnementale en proposant un **programme d’accompagnement.**<br><br> Nous donnons ainsi à nos sociétaires les clés pour s’engager dans l'adaptation de leur modèle à ces enjeux afin de réaliser leur **bilan carbone** et mettre en œuvre un **plan d’actions pour réduire leurs émissions.**",
            "image": discussionImg,
            "button": true,
            "buttonText": "En savoir plus",
            "buttonAlignment": "left",
            "buttonUrl": "https://wecount.notion.site/Promotion-Bilan-Carbone-BTP-BTP-Rh-ne-x-L-Auxiliaire-BTP-x-WeCount-cc55d6483e624daab0b6e0398244bbbb",
            "buttonInNewTab": true,
            "imageFirst": true,
        },
        {
            "texte": "Souhaitant accompagner les acteurs du BTP dans leurs enjeux du quotidien et de demain, L’Auxiliaire s’associe à la start-up Akanthas et propose ainsi à ses sociétaires d’**optimiser la gestion de leurs déchets de chantier** grâce à l’intelligence artificielle et de bénéficier d’un diagnostic connecté de leur activité de tri et d’enlèvement. <br><br> Nous sommes également attentifs à la question du **réemploi des matériaux** afin de proposer à nos sociétaires des solutions d’assurance adaptées et ainsi promouvoir leur utilisation.",
            "image": poubelleImg,
            "imageFirst": false,
        }
    ];
    return (
        <>
        <Helmet>
            <body />
        </Helmet>
        <Layout>
            <SEO title="Nos engagements environnementaux" newTitleTemplate="%s · L'Auxiliaire" description="L’Auxiliaire met en œuvre plusieurs actions afin de réduire au quotidien son impact sur l’environnement et d’accompagner la transition de ses sociétaires. Découvrez nos différents engagements."/>
            <HeaderImage
            title="Préserver demain."
            text="Aux côtés de tous les acteurs qui ont décidé que chaque geste compte, nous soutenons par nos actions la transition environnementale. Voici comment."
            imagePath={headerImg}
            crumbLabel="Nos engagements pour l’environnement"
            location={location}
            />
            <section className="engagements__section1">
                <h2>Nos leviers d’action en <br/> faveur de <strong>l’environnement.</strong></h2>
                <div className="leviers">
                    <div className="leviers__items">
                    {leviers.map((levier, i) => {
                    return (
                        <BoxContent key={i}>
                            <h3>
                                <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                children={levier}
                                />
                            </h3>
                        </BoxContent>
                    )
                    })}
                    </div>
                </div>
            </section>

            <section className="engagements__section2">
                <h2>Nous maîtrisons notre<br/><strong> impact au quotidien</strong>&nbsp;!</h2>
                <div>
                {impacts.map((impact, i) => {
                    return (
                        <TextImageContent
                        imgPath={impact.image}
                        isImgFirst={impact.imageFirst}
                        imgAlt=""
                        key={i}
                      >
                        <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                            children={impact.texte}
                        />
                      </TextImageContent>
                    );
                })}
                </div>
            </section>

            <section className="engagements__section2 engagements-grey-section">
                <h2>Nous <strong>accompagnons <br/>la transition</strong> de nos <br/>sociétaires&nbsp;!</h2>
                <div>
                {transitionSocietaires.map((transition, i) => {
                    return (
                        <TextImageContent
                        imgPath={transition.image}
                        isImgFirst={transition.imageFirst}
                        imgAlt=""
                        key={i}
                      >
                        <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                            children={transition.texte}
                        />
                        <br/>
                            {
                                transition.button &&
                                <div className={'transition-button-block ' + ('button-' + (transition.buttonAlignment ?? 'center'))}>
                                    <Button
                                        text={transition.buttonText ?? "découvrir l’offre"}
                                        to={transition.buttonUrl}
                                        target={transition.buttonInNewTab ? '_blank' : '_self'}
                                    />
                                </div>
                            }
                      </TextImageContent>
                    );
                })}
                </div>
            </section>

            <section className="engagements__section2">
                <h2>Nous agissons <br/> aujourd’hui pour <br/><strong>préserver demain</strong>&nbsp;!</h2>
                <div>
                    <TextImageContent
                        imgPath={batimentImg}
                        isImgFirst={false}
                        imgAlt=""
                    >
                        <p>
                        Assureur à vocation locale, nous menons une <strong>démarche d’investissement responsable et vertueuse</strong> à destination des PME et de l’activité du tissu économique régional. Nos investissements sont ainsi en majorité tournés vers des fonds dédiés à l’<strong>économie réelle</strong> et au soutien de l’innovation.
                        </p>
                        <br></br>
                        <p>
                        Nous sommes également engagés en faveur du <strong>financement de projets de construction ou de rénovation lourde à hautes performances énergétiques</strong> sur le territoire national.
                        </p>
                    </TextImageContent>
                </div>
            </section>
        </Layout>
        </>
    );
};

export default NosEngagementsEnvironnementaux;
